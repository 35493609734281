import type { InboundItemDto, ItemDto, OutboundResponseDto } from '@wanda-space/ops-types'
import {
  type AccountId,
  type AdminCreateItemDto,
  type AdminItemResponseDto,
  type ItemImageResponseDto,
  type OpsType,
  type PaginatedResponseDto,
  type StorageItemState,
  type StorageItemType,
  type SupportedCities,
  adminItemResponseDto,
} from '@wanda-space/types'

import { request } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'
import { opsBaseUrl } from '../config'
import type { HistoryObject } from '../types'
import type { GroupBy, OrderByDirection } from '../types-old'

export interface CreateItemResponseDto {
  id: string
}
export interface CreateItemQueryParams {
  ownerId: string
  orderId?: string
}

export async function fetchItems(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
  withDeleted?: boolean
}): Promise<{ items: AdminItemResponseDto[]; count: number }> {
  const res = await fetchChunk({ url: '/admin/item', ...params, search: params.search?.trim() })
  return { ...res, items: adminItemResponseDto.array().parse(res.items) }
}

export async function fetchItem(params: {
  id: string
  token: string
  withDeleted?: boolean
}): Promise<AdminItemResponseDto> {
  return adminItemResponseDto.parse(
    await request({
      url: `/admin/item/${params.id}`,
      token: params.token,
      params: new URLSearchParams({ withDeleted: Boolean(params.withDeleted).toString() }),
      method: 'GET',
    }),
  )
}
export function fetchOpsItems(params: {
  orderBy?: string
  orderDir?: 'ASC' | 'DESC'
  itemsPerPage?: number
  page?: number
  search?: string | undefined
  filters?: ApiFilters | undefined
  token: string
}): Promise<{ items: InboundItemDto[]; count: number }> {
  return fetchChunk({
    baseUrl: opsBaseUrl,
    url: '/v1/items/',
    orderBy: 'simpleId',
    orderDir: 'ASC',
    page: params.page || 1,
    itemsPerPage: 100,
    filters: undefined,
    ...params,
    search: params.search?.trim(),
  })
}

export async function fetchOpsItemsByOrder({
  token,
  orderId,
}: {
  token: string
  orderId: string
}): Promise<ItemDto[]> {
  return (
    await request<{ items: ItemDto[]; count: number }>({
      baseUrl: opsBaseUrl,
      method: 'GET',
      url: `/v1/items/by-order/${orderId}/`,
      token,
    })
  ).items
}

export function fetchItemsToPick(params: {
  token: string
  filters?: ApiFilters | undefined
}): Promise<Record<string, ItemDto[]>> {
  return request({
    baseUrl: opsBaseUrl,
    method: 'GET',
    url: '/v1/items/picking/',
    token: params.token,
    params: new URLSearchParams({ ...params.filters }),
  })
}

export const fetchOpsItem = async (itemId: string, token: string): Promise<ItemDto> => {
  const url = `v1/items/${itemId}/`

  return await request({
    method: 'GET',
    url,
    token,
    baseUrl: opsBaseUrl,
  })
}

export function fetchItemHistory(
  itemId: string,
  token: string,
): Promise<{ count: number; items: HistoryObject<AdminItemResponseDto>[] }> {
  return request({
    method: 'GET',
    url: `/admin/item/${itemId}/history`,
    token,
  })
}

export function fetchItemOpsHistory(
  itemId: string,
  token: string,
): Promise<{ count: number; items: HistoryObject<ItemDto>[] }> {
  return request({
    method: 'GET',
    url: `/v1/items/${itemId}/history/`,
    token,
    baseUrl: opsBaseUrl,
  })
}

export async function createItem(
  form: FormData,
  queryParams: CreateItemQueryParams,
  token: string,
): Promise<AdminItemResponseDto> {
  const params = new URLSearchParams({ ...queryParams })
  return adminItemResponseDto.parse(
    await request({
      method: 'POST',
      url: 'admin/item',
      body: form,
      token,
      params,
    }),
  )
}

export const updateItem = async (
  {
    itemId,
    name,
    type,
    ownerId,
    state,
    warehouseId,
  }: {
    itemId: string
    ownerId: string
    state: StorageItemState
    name: string
    type: StorageItemType
    warehouseId?: string
  },
  token: string,
  signal?: AbortSignal,
): Promise<AdminItemResponseDto> => {
  const params = new URLSearchParams({
    ownerId,
  })

  return adminItemResponseDto.parse(
    await request({
      method: 'PUT',
      url: `/admin/item/${itemId}`,
      params,
      body: { state, name, type, warehouseId },
      token,
      signal,
    }),
  )
}

export function fetchCountItems({
  from,
  to,
  groupBy,
  page,
  itemsPerPage,
  search,
  orderBy,
  orderDir,
  signal,
  token,
}: {
  from?: number
  to?: number
  groupBy?: GroupBy[]
  page?: number
  itemsPerPage?: number
  search?: string
  orderBy?: string
  orderDir?: OrderByDirection
  signal?: AbortSignal
  token: string
}): Promise<any> {
  const params = new URLSearchParams()
  if (from) {
    params.set('from', from.toString())
  }
  if (to) {
    params.set('to', to.toString())
  }
  if (search) {
    params.set('search', search.trim())
  }
  if (groupBy) {
    groupBy.forEach((groupBy) => {
      params.append('groupBy', groupBy)
    })
  }
  if (page) {
    params.set('page', page.toString())
  }
  if (itemsPerPage) {
    params.set('itemsPerPage', itemsPerPage.toString())
  }
  if (orderBy) {
    params.set('orderBy', orderBy)
  }
  if (orderDir) {
    params.set('orderDir', orderDir)
  }
  return request({
    method: 'GET',
    url: '/admin/item/count',
    params,
    signal,
    token,
  })
}

export async function updateItemProduct({
  id,
  data,
  token,
}: {
  id: string
  data: { productId: string; productPrice: number }
  token: string
}): Promise<AdminItemResponseDto> {
  return adminItemResponseDto.parse(
    await request({
      method: 'PUT',
      url: `/admin/item/${id}/product`,
      token,
      body: data,
    }),
  )
}

export async function updateItemOpsType({
  id,
  data,
  token,
}: {
  id: string
  data: { opsType: OpsType }
  token: string
}): Promise<AdminItemResponseDto> {
  return adminItemResponseDto.parse(
    await request({
      method: 'PUT',
      url: `/admin/item/${id}/product`,
      token,
      body: data,
    }),
  )
}

export async function updateItemPaymentCommitment({
  id,
  data,
  token,
}: {
  id: string
  data: { paymentCommitment: { startDate: string; endDate: string } | null }
  token: string
}): Promise<AdminItemResponseDto> {
  return adminItemResponseDto.parse(
    await request({
      method: 'PUT',
      url: `/admin/item/${id}/payment-commitment`,
      token,
      body: data,
    }),
  )
}

export async function updateItemLoad({
  id,
  data,
  token,
}: {
  id: string
  data: { load?: number }
  token: string
}): Promise<AdminItemResponseDto> {
  return adminItemResponseDto.parse(
    await request({
      method: 'PUT',
      url: `/admin/item/${id}/load`,
      token,
      body: data,
    }),
  )
}

export const uploadItemImage = async ({
  itemId,
  form,
  token,
}: {
  itemId: string
  token: string
  form: FormData
}): Promise<{ imageId: string }> => {
  return await request({
    method: 'POST',
    url: `/admin/item/${itemId}/image`,
    body: form,
    token: token,
  })
}

export const deleteItemImage = async (
  itemId: string,
  imageId: string,
  ownerId: AccountId,
  token: string,
): Promise<string[]> => {
  const params = new URLSearchParams({
    ownerId,
  })
  return await request({
    method: 'DELETE',
    url: `/admin/item/${itemId}/image/${imageId}`,
    token,
    params,
  })
}

export const createManyItems = async ({
  payloads,
  token,
  ownerId,
}: {
  payloads: AdminCreateItemDto[]
  token: string
  ownerId: string
}): Promise<AdminItemResponseDto[]> => {
  return adminItemResponseDto.array().parse(
    await request({
      method: 'POST',
      url: '/admin/item/many',
      token,
      body: payloads,
      params: new URLSearchParams({
        ownerId,
      }),
    }),
  )
}

export const backfillItems = async ({ ids, token }: { ids: string[]; token: string }) =>
  request({
    method: 'POST',
    baseUrl: opsBaseUrl,
    url: '/v1/items/backfill/',
    token,
    body: { ids },
  })

export interface OutboundFilters {
  fromDate: string
  toDate: string
  city: SupportedCities | 'all'
}
export function fetchOutbound(
  { city, ...filters }: OutboundFilters,
  token: string,
): Promise<OutboundResponseDto> {
  const params = new URLSearchParams({ ...filters })
  if (city !== 'all') {
    params.set('city', city)
  }

  return request({
    baseUrl: opsBaseUrl,
    method: 'GET',
    url: '/v1/items/outbound-1/',
    token,
    params,
  })
}
export interface PickingInWarehouseFilters {
  fromDate: string
  toDate: string
  warehouse: string | 'all'
  city: SupportedCities | 'all'
}
export function fetchPickingInWarehouse(
  { city, warehouse, ...filters }: PickingInWarehouseFilters,
  token: string,
): Promise<{ items: ItemDto[] }> {
  const params = new URLSearchParams({ ...filters })
  if (city !== 'all') {
    params.set('city', city)
  }
  if (warehouse !== 'all') {
    params.set('warehouse', warehouse)
  }

  return request({
    baseUrl: opsBaseUrl,
    method: 'GET',
    url: '/v1/items/picking-in-warehouse/',
    token,
    params,
  })
}

export type ServiceOutboundFilters = any
export function fetchServiceOutbound(
  { city, warehouse }: ServiceOutboundFilters,
  token: string,
): Promise<{ items: InboundItemDto[] }> {
  const params = new URLSearchParams()
  if (city !== 'all') {
    params.set('city', city)
  }
  if (warehouse !== 'all') {
    params.set('warehouse', warehouse)
  }

  return request({
    baseUrl: opsBaseUrl,
    method: 'GET',
    url: '/v1/items/service-outbound/',
    token,
    params,
  })
}

export async function fetchImageDetailByImageId(itemId: string, imageId: string, token: string) {
  return request<ItemImageResponseDto>({
    method: 'GET',
    url: `admin/item/${itemId}/image-detail/${imageId}`,
    token,
  })
}

export async function fetchImageDetails(itemId: string, token: string) {
  return request<PaginatedResponseDto<ItemImageResponseDto>>({
    method: 'GET',
    url: `admin/item/${itemId}/image-details`,
    token,
  })
}
