import {
  type AccountId,
  type AddItemsToListingRequestBodyDto,
  type AdminItemResponseDto,
  type AdminListingResponseDto,
  type CreateListingRequestBodyDto,
  type ItemImageResponseDto,
  type ListingDimensionTypes,
  ListingImagesResponseDto,
  type PaginatedResponseDto,
  type UpadteListingPayoutStatusBodyDto,
  type UpdateListingConfigRequestDto,
  type UpdateListingDescriptionDto,
  type UpdateListingExtraDescriptiveInfo,
  type UpdateListingStatusDto,
  type UpdatePriceDto,
  type WandaId,
  adminListingResponseDto,
  listingBidResponseDto,
} from '@wanda-space/types'

import type { Currency } from '@wanda-space/ops-types'
import { request } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'
import { ApiError } from '../error'

export type ListingItems = AdminListingResponseDto['storageItems']

export async function fetchListings(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  withDeleted?: boolean
  token: string
}): Promise<{ items: AdminListingResponseDto[]; count: number }> {
  const response = await fetchChunk({
    url: '/admin/listing',
    ...params,
    withDeleted: params.withDeleted ?? false,
    search: params.search?.trim(),
  })
  return { ...response, items: adminListingResponseDto.array().parse(response.items) }
}

export async function fetchListing(
  { id, withDeleted }: { id: string; withDeleted?: boolean },
  token: string,
): Promise<AdminListingResponseDto> {
  const response = await fetchListings({
    token,
    filters: { id },
    itemsPerPage: 1,
    page: 0,
    orderDir: 'ASC',
    orderBy: 'id',
    search: undefined,
    withDeleted,
  })
  if (response.count === 1) {
    return adminListingResponseDto.parse(response.items[0])
  }
  throw new ApiError('/admin/listings', { method: 'GET' }, { status: 404 } as Response)
}

export async function createListing(
  accountId: AccountId,
  data: CreateListingRequestBodyDto,
  token: string,
): Promise<AdminListingResponseDto> {
  const params = new URLSearchParams({ accountId })
  return adminListingResponseDto.parse(
    await request({
      method: 'POST',
      url: 'admin/listing',
      token,
      params,
      body: data,
    }),
  )
}

export function uploadListingImage(
  accountId: AccountId,
  form: FormData,
  listingId: string,
  token: string,
): Promise<void> {
  const params = new URLSearchParams({ accountId })
  return request({
    method: 'POST',
    url: `admin/listing/${listingId}/image`,
    body: form,
    token,
    params,
  })
}

export function deleteListingImage(
  listingId: string,
  imageId: string,
  accountId: AccountId,
  token: string,
): Promise<void> {
  const params = new URLSearchParams({ accountId: accountId })
  return request({
    method: 'DELETE',
    url: `admin/listing/${listingId}/image/${imageId.split('/')[1]}`,
    token,
    params,
  })
}

export async function editListingConfig(
  data: UpdateListingConfigRequestDto,
  listingId: string,
  token: string,
): Promise<AdminListingResponseDto> {
  return adminListingResponseDto.parse(
    await request({
      method: 'PUT',
      url: `admin/listing/${listingId}/config`,
      body: data,
      token,
    }),
  )
}
export async function editListingPrice(
  data: UpdatePriceDto,
  accountId: AccountId,
  listingId: string,
  token: string,
): Promise<AdminListingResponseDto> {
  const params = new URLSearchParams({ accountId: accountId })
  return adminListingResponseDto.parse(
    await request({
      method: 'PUT',
      url: `admin/listing/${listingId}/price`,
      params,
      body: data,
      token,
    }),
  )
}
export async function editListingStatus(
  data: UpdateListingStatusDto,
  listingId: string,
  token: string,
): Promise<AdminListingResponseDto> {
  return adminListingResponseDto.parse(
    await request({
      method: 'PUT',
      url: `admin/listing/${listingId}/status`,
      body: data,
      token,
    }),
  )
}

export async function reverseListing(
  listingId: string,
  token: string,
): Promise<AdminListingResponseDto> {
  return adminListingResponseDto.parse(
    await request({
      method: 'PUT',
      url: `admin/listing/${listingId}/reverse`,
      body: undefined,
      token,
    }),
  )
}
export function promoteListingImage(
  accountId: AccountId,
  listingId: string,
  imageId: string,
  token: string,
) {
  const params = new URLSearchParams({ accountId })
  return request({
    method: 'POST',
    url: `admin/listing/${listingId}/image/${imageId}/primary`,
    token,
    params,
    body: {},
  })
}

export async function editListingPayoutStatus(
  data: UpadteListingPayoutStatusBodyDto,
  listingId: string,
  token: string,
): Promise<AdminListingResponseDto> {
  return request({
    method: 'PUT',
    url: `admin/listing/${listingId}/payout-status`,
    body: data,
    token,
  })
}

export function fetchSellableStoredItems(
  itemsPerPage: number,
  page: number,
  accountId: string,
  token: string,
) {
  const params = new URLSearchParams({
    accountId,
    page: String(page),
    itemsPerPage: String(itemsPerPage),
  })

  return request<{ items: AdminItemResponseDto[]; count: number }>({
    method: 'GET',
    url: 'admin/listing/sellable-stored-items',
    params,
    token,
  })
}

export async function updateListingItems(
  data: AddItemsToListingRequestBodyDto,
  listingId: string,
  token: string,
) {
  return adminListingResponseDto.parse(
    await request({
      method: 'PUT',
      url: `admin/listing/${listingId}/items`,
      body: data,
      token,
    }),
  )
}

export async function fetchListingBids(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}) {
  const response = await fetchChunk({
    url: '/admin/listing/bids',
    ...params,
    search: params.search?.trim(),
  })
  return { ...response, items: listingBidResponseDto.array().parse(response.items) }
}

export interface CreateListingDto {
  name: string
  price: number
  currency: Currency
  storageItems: string[]
  description?: string
  bankAccount: string
}

export async function editListingDescription(
  data: UpdateListingDescriptionDto,
  listingId: string,
  sellerAccountId: string,
  token: string,
): Promise<AdminListingResponseDto> {
  return adminListingResponseDto.parse(
    await request({
      method: 'PUT',
      url: `/admin/listing/${listingId}/description`,
      body: data,
      params: new URLSearchParams({ accountId: sellerAccountId }),
      token,
    }),
  )
}

export async function editListingExtraDescriptiveInfo(
  data: UpdateListingExtraDescriptiveInfo,
  listingId: string,
  sellerAccountId: string,
  token: string,
): Promise<AdminListingResponseDto> {
  return adminListingResponseDto.parse(
    await request({
      method: 'PUT',
      url: `/admin/listing/${listingId}/extra-descriptive-info`,
      body: data,
      params: new URLSearchParams({ accountId: sellerAccountId }),
      token,
    }),
  )
}

export async function editListingDimensions(
  data: number,
  type: ListingDimensionTypes,
  listingId: string,
  sellerAccountId: string,
  token: string,
): Promise<AdminListingResponseDto> {
  return adminListingResponseDto.parse(
    await request({
      method: 'PUT',
      url: `/admin/listing/${listingId}/dimensions/${type}`,
      body: { value: data },
      params: new URLSearchParams({ accountId: sellerAccountId }),
      token,
    }),
  )
}

export async function editIsInspectedByWanda(
  data: boolean,
  listingId: string,
  sellerAccountId: string,
  token: string,
): Promise<AdminListingResponseDto> {
  return await request({
    method: 'PUT',
    url: `/admin/listing/${listingId}/inspected-by-wanda`,
    body: { inspected: data },
    params: new URLSearchParams({ accountId: sellerAccountId }),
    token,
  })
}

export async function fetchListingImageDetailByImageId(
  listingId: string,
  imageId: string,
  sellerAccountId: string,
  token: string,
) {
  return request<ItemImageResponseDto>({
    method: 'GET',
    url: `admin/listing/${listingId}/image-detail/${imageId}`,
    params: new URLSearchParams({ accountId: sellerAccountId }),
    token,
  })
}

export async function fetchListingImageDetails(
  listingId: string,
  sellerAccountId: string,
  token: string,
) {
  return request<PaginatedResponseDto<ItemImageResponseDto>>({
    method: 'GET',
    url: `admin/listing/${listingId}/image-details`,
    params: new URLSearchParams({ accountId: sellerAccountId }),
    token,
  })
}
